import { IEntityDto } from '@/types/IEntityDto';
import { IDictionaryEntityDto } from '@/types/IDictionaryEntityDto';
import { IDictViolationTypeDto } from '@/types/IDictViolationTypeDto';
import { IJobExecutionDto } from '@/types/IJobExecutionDto';

export interface IDictNotificationType extends IDictionaryEntityDto {
  // Интервал напоминаний в днях
  remindInterval: number;

  // Количество напоминаний
  remindCount: number;
}

export enum NotificationTypeCode {
  VIOLATION_CHANGED = 'VIOLATION_CHANGED',
  VIOLATION_REMIND = 'VIOLATION_REMIND',
  VIOLATION_CLOSED = 'VIOLATION_CLOSED',
  VIOLATION_OPENED = 'VIOLATION_OPENED',
}

export enum NotificationStatusCode {
  NEW = 'NEW',
  SENT = 'SENT',
  READ = 'READ',
  SEND_ERROR = 'SEND_ERROR',
}

export interface INotificationDto extends IEntityDto {
  // Схема параметров запуска (JSON)
  templateParams: any;

  // Статус уведомления
  status: IDictionaryEntityDto;

  // ИНН налогоплательщика
  tin: string;

  // Налоговый период
  taxPeriod: number;

  // Тип нарушения
  violationType: IDictViolationTypeDto;

  // Сумма нарушения
  violationSum: number;

  // Дата и время открытия нарушения
  violationOpenedAt: string;

  // Дата и время отсылки
  sentAt: string;

  // Тип уведомления
  type: IDictNotificationType;

  // Вид уведомления
  kind: IDictionaryEntityDto;

  // Идентификатор нарушения
  violationId: string;

  // Идентификатор версии нарушения
  violationRecId: string;

  // Идентификатор предыдущего уведомления для уведомления-напоминания
  originId: string;

  // Журнал запуска задач
  execution: IJobExecutionDto;
}

export interface INotificationListItemView {
  id: string;
  created: string;
  updated: string;
  type: string;
  notificationStatusBadgeColor: string;
  notificationStatusBadgeLabel: string;
  violationTypeTitle: string;
}
