import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { AuthData } from '@/api/services/userService/types';
import stores from '@/store/core/RootStore';
import router from '@/router';
import services from '@/api/services';

type ExtendedAxiosRequestConfig =
  | (AxiosRequestConfig & {
      isRetry: boolean;
    })
  | undefined;

const token =
  'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJraGFyYXRpYW4uYXJ0aHVyQHVuaW9uc21hcnR0ZWNoLmNvbSIsInR5cGUiOiJDVVNUT01FUiIsImlkIjoyMDk0NywibHBJZCI6MjA5NTAsInNjb3BlcyI6WyJST0xFX0NVU1RPTUVSIl0sImlzcyI6ImNmcnMiLCJpYXQiOjE3MDE4NjEzMTQsImV4cCI6MjAxNzQ4MDUxNH0.DuH88kUG9M2Xf4aA-Xi2_wMBIvLDtjY4anG6JmVxYxkwcOgKRQTWHTt-YiTzQQUyaFn4SdAvo0EuHY0al7pkCQ';
const isProduction = process.env.NODE_ENV === 'production';
const API_URL = isProduction
  ? 'https://elastic-ext.dev-24x7.com'
  : 'http://10.36.98.100:9200';

const elasticApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    ...(isProduction && {
      'access-token': token,
    }),
  },
  transformResponse: (response) => {
    const data = JSON.parse(response);
    const items = data?.hits?.hits || [data];
    const aggregations = data?.aggregations;
    if (items?.length || (aggregations && Object.keys(aggregations).length)) {
      return {
        data: items.map((item: any) => item._source),
        totalItems: data?.hits?.total?.value || 0,
        aggregations: aggregations || [],
      };
    }
    return null;
  },
});

const invoiceApi = axios.create({
  baseURL: 'https://sto-invoice-web.dev-24x7.com/api/',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '68bdfca5-631f-45c2-914f-5917f194df7e',
  },
});

const stoFiscalDataApi = axios.create({
  baseURL: 'https://sto-tax-fiscal-data-service.dev-24x7.com/',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '68bdfca5-631f-45c2-914f-5917f194df7e',
  },
});

const authApi = axios.create({
  baseURL: 'https://sto-cloud-fiscal-register-service.dev-24x7.com/api/',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '68bdfca5-631f-45c2-914f-5917f194df7e',
  },
});

const stoTaxPrinterProxyApi = axios.create({
  baseURL: 'https://sto-tax-sto-printer-proxy.dev-24x7.com/',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '68bdfca5-631f-45c2-914f-5917f194df7e',
  },
});

const getLocale = () => localStorage.getItem('lang') || 'en';
const stoCtaNotificationApi = axios.create({
  baseURL: 'https://demo-sto-cta-notification-api.dev-24x7.com',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    locale: getLocale(),
  },
});

const REQUEST_INTERCEPTORS = [
  (config: InternalAxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Authorization'] = getBearer(stores.user.token);
    }
    return config;
  },
  (error: any) => error,
];

const RESPONSE_INTERCEPTORS = [
  (response: AxiosResponse) => response,
  async (error: any) => {
    console.log(error);
    const config = error.config as ExtendedAxiosRequestConfig;
    if (error?.response?.status === 401 && config && !config.isRetry) {
      config.isRetry = true;
      try {
        isRefreshing = isRefreshing ? isRefreshing : refresh();
        const data = await isRefreshing;
        isRefreshing = null;
        stores.user.setKey(data);
        return invoiceApi.request(config);
      } catch (error) {
        console.error(error);
        stores.user.removeKey();
        await router.push('/auth');
      }
    }
    return Promise.reject(error);
  },
];

export const getBearer = (token?: string) => `Bearer ${token || ''}`;

const refresh = async () => {
  const refreshToken: string = stores.user.refreshToken || '';
  const response = await services.user.refresh({ refreshToken });
  return response.data;
};

let isRefreshing: Promise<AuthData> | null = null;

invoiceApi.interceptors.request.use(...REQUEST_INTERCEPTORS);
invoiceApi.interceptors.response.use(...RESPONSE_INTERCEPTORS);

stoFiscalDataApi.interceptors.request.use(...REQUEST_INTERCEPTORS);
stoFiscalDataApi.interceptors.response.use(...RESPONSE_INTERCEPTORS);

stoTaxPrinterProxyApi.interceptors.request.use(...REQUEST_INTERCEPTORS);
stoTaxPrinterProxyApi.interceptors.response.use(...RESPONSE_INTERCEPTORS);

stoCtaNotificationApi.interceptors.request.use(
  // @ts-ignore
  (config) => {
    config.headers.locale = getLocale();
    return config;
  },
);

export {
  elasticApi,
  invoiceApi,
  authApi,
  stoFiscalDataApi,
  stoTaxPrinterProxyApi,
  stoCtaNotificationApi,
};
