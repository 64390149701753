import { IMessageDto } from '@/types/IMessageDto';
import DateTime from '@/entities/DateTime';
import { IPagedDto } from '@/types/IPagedDto';
import { DictionaryEntity } from '@/entities/DictionaryEntity';
import NotificationEntity from '@/entities/NotificationEntity';
import { ContentEntity, DictContentTypeEntity } from '@/entities/ContentEntity';
import { NotificationStatusCode } from '@/types/INotificationDto';
import NotificationTypeEntity from '@/entities/NotificationTypeEntity';

export class MessageEntity {
  // Идентификатор записи
  public id: string;

  // Дата и время создания
  public created: DateTime;

  // Дата и время изменения
  public updated: DateTime;

  // Статус сообщения (DictMessageStatus)
  public status: DictionaryEntity;

  // Дата и время отсылки
  public sentAt: DateTime;

  // Уведомление
  public notification: NotificationEntity;

  // Заголовок сообщения
  public subject: string;

  // Тело сообщения(TEXT)
  public body: string;

  // Тело сообщения(BINARY) string($byte)
  public bodyBin: string;

  // Содержимое сообщения
  public attachments: IPagedDto<ContentEntity>;

  // Канал доставки уведомления (DictMessageChannel)
  public channel: DictionaryEntity;

  // Тип содержимого
  public bodyContentType: DictContentTypeEntity;

  // Категория уведомления (DictNotificationCategory)
  public category: DictionaryEntity;

  // Тип уведомления
  public type: NotificationTypeEntity;

  public kind: DictionaryEntity;

  constructor(dto: IMessageDto | null) {
    if (dto == null) {
      this.id = '';
      this.created = new DateTime(null);
      this.updated = new DateTime(null);
      this.status = new DictionaryEntity(null);
      this.notification = new NotificationEntity(null);
      this.subject = '';
      this.body = '';
      this.bodyBin = '';
      this.attachments = {
        data: [],
        page: { page: 0, size: 0 },
        total: 0,
      };
      this.sentAt = new DateTime(null);
      this.channel = new DictionaryEntity(null);
      this.bodyContentType = new DictContentTypeEntity(null);
      this.category = new DictionaryEntity(null);
      this.type = new NotificationTypeEntity(null);
      this.kind = new DictionaryEntity(null);

      return;
    }

    this.id = dto.id;
    this.created = new DateTime(dto.created);
    this.updated = new DateTime(dto.updated);
    this.status = new DictionaryEntity(dto.status);
    this.notification = new NotificationEntity(dto.notification);
    this.subject = dto.subject;
    this.body = dto.body;
    this.bodyBin = dto.bodyBin;
    this.attachments = {
      data: (dto.attachments?.data || []).map(
        (item) => new ContentEntity(item),
      ),
      page: { ...dto.attachments?.page },
      total: dto.attachments?.total || 0,
    };
    this.sentAt = new DateTime(dto.sentAt);
    this.channel = new DictionaryEntity(dto.channel);
    this.bodyContentType = new DictContentTypeEntity(dto.bodyContentType);
    this.category = new DictionaryEntity(dto.category);
    this.type = new NotificationTypeEntity(dto.type);
    this.kind = new DictionaryEntity(dto.kind);
  }

  public get isRead(): boolean {
    return (
      this.status != null && this.status.code === NotificationStatusCode.READ
    );
  }

  public getListItemView() {
    return {
      messageId: this.id,
      notificationId: this.notification.id,
      type: this.notification.kind.title.localizedValue,
      created: this.notification.created.dateTime,
      isRead: this.isRead,
      subjectShort:
        this.subject.length > 100
          ? this.subject.substring(0, 100) + '...'
          : this.subject,
      subjectFull: this.subject,
    };
  }
}
