// DictContentType - Тип содержимого
export const getDictContentType = (id: string) =>
  `/api/v1/dict_content_type/${id}`;
export const putDictContentType = (id: string) =>
  `/api/v1/dict_content_type/${id}`;
export const postDictContentTypeSearch = () =>
  '/api/v1/dict_content_type/search';

// DictContentKind - Категория содержимого
export const getDictContentKind = (id: string) =>
  `/api/v1/dict_content_kind/${id}`;
export const putDictContentKind = (id: string) =>
  `/api/v1/dict_content_kind/${id}`;
export const postDictContentKindSearch = () =>
  `/api/v1/dict_content_kind/search`;

// DictLanguage - Язык
export const getDictLanguage = (id: string) => `/api/v1/dict_language/${id}`;
export const putDictLanguage = (id: string) => `/api/v1/dict_language/${id}`;
export const postDictLanguageSearch = () => `/api/v1/dict_language/search`;
export const postDictLanguageCreate = () => `/api/v1/dict_language/`;

// MessageTemplate - Шаблон сообщения
export const getMessageTemplate = (id: string) =>
  `/api/v1/message_template/${id}`;
export const putMessageTemplate = (id: string) =>
  `/api/v1/message_template/${id}`;
export const postMessageTemplateSearch = () =>
  `/api/v1/message_template/search`;
export const postMessageTemplateCreate = () => `/api/v1/message_template/`;

// Content - Содержимое сообщения
export const postContentSearch = () => `/api/v1/content/search`;
export const getContent = (id: string) => `/api/v1/content/${id}`;
export const getContentBodyBin = (id: string) =>
  `/api/v1/content/${id}/bodyBin`;

// DictNotificationKind - Вид уведомления
export const getDictNotificationKind = (id: string) =>
  `/api/v1/dict_notification_kind/${id}`;
export const putDictNotificationKind = (id: string) =>
  `/api/v1/dict_notification_kind/${id}`;
export const postDictNotificationKindSearch = () =>
  `/api/v1/dict_notification_kind/search`;
export const postDictNotificationKindCreate = () =>
  `/api/v1/dict_notification_kind/`;

// DictNotificationStatus - Статус уведомления
export const getDictNotificationStatus = (id: string) =>
  `/api/v1/dict_notification_status/${id}`;
export const putDictNotificationStatus = (id: string) =>
  `/api/v1/dict_notification_status/${id}`;
export const postDictNotificationStatusSearch = () =>
  `/api/v1/dict_notification_status/search`;

// ContentTemplate - Шаблон содержимого сообщения
export const getContentTemplate = (id: string) =>
  `/api/v1/content_template/${id}`;
export const putContentTemplate = (id: string) =>
  `/api/v1/content_template/${id}`;
export const postContentTemplateSearch = () =>
  `/api/v1/content_template/search`;
export const postContentTemplateCreate = () => `/api/v1/content_template/`;

// DictNotificationType - Тип уведомления
export const getDictNotificationType = (id: string) =>
  `/api/v1/dict_notification_type/${id}`;
export const putDictNotificationType = (id: string) =>
  `/api/v1/dict_notification_type/${id}`;
export const postDictNotificationTypeSearch = () =>
  `/api/v1/dict_notification_type/search`;
export const postDictNotificationTypeCreate = () =>
  `/api/v1/dict_notification_type/`;

// DictMessageChannel - Канал доставки уведомления
export const getDictMessageChannel = (id: string) =>
  `/api/v1/dict_message_channel/${id}`;
export const putDictMessageChannel = (id: string) =>
  `/api/v1/dict_message_channel/${id}`;
export const postDictMessageChannelSearch = () =>
  `/api/v1/dict_message_channel/search`;

// DictNotificationCategory - Категория уведомления
export const getDictNotificationCategory = (id: string) =>
  `/api/v1/dict_notification_category/${id}`;
export const putDictNotificationCategory = (id: string) =>
  `/api/v1/dict_notification_category/${id}`;
export const postDictNotificationCategorySearch = () =>
  `/api/v1/dict_notification_category/search`;
export const postDictNotificationCategoryCreate = () =>
  `/api/v1/dict_notification_category/`;

// NotificationTemplate - Шаблон уведомления
export const getNotificationTemplate = (id: string) =>
  `/api/v1/notification_template/${id}`;
export const putNotificationTemplate = (id: string) =>
  `/api/v1/notification_template/${id}`;
export const postNotificationTemplateDeactivate = (id: string) =>
  `/api/v1/notification_template/${id}/deactivate`;
export const postNotificationTemplateActivate = (id: string) =>
  `/api/v1/notification_template/${id}/activate`;
export const postNotificationTemplateSearch = () =>
  `/api/v1/notification_template/search`;
export const postNotificationTemplateCreate = () =>
  `/api/v1/notification_template/`;

// Notification - Уведомление
export const putNotificationRead = (id: string) =>
  `/api/v1/notification/${id}/read`;
export const postNotificationStatuses = () => `/api/v1/notification/statuses`;
export const postNotificationSearch = () => `/api/v1/notification/search`;
export const postNotificationPlain = () => `/api/v1/notification/plain`;
export const postNotificationCreate = () => `/api/v1/notification/create`;
export const postNotificationBatchCreate = () =>
  `/api/v1/notification/batchCreate`;
export const getNotification = (id: string) => `/api/v1/notification/${id}`;
export const getNotificationDefaultContent = (id: string) =>
  `/api/v1/notification/${id}/defaultContent`;

// Message - Сообщение
export const postMessageTaxpayerSearch = () =>
  `/api/v1/message/taxpayer/search`;
export const postMessageSearch = () => `/api/v1/message/search`;
export const getMessage = (id: string) => `/api/v1/message/${id}`;
export const getMessageBodyBin = (id: string) =>
  `/api/v1/message/${id}/bodyBin`;
export const getMessageByIdForTaxpayer = (id: string) =>
  `/api/v1/message/taxpayer/${id}`;

// DictActiveStatus - Статус активности записи
export const getDictActiveStatus = (id: string) =>
  `/api/v1/dict_active_status/${id}`;
export const putDictActiveStatus = (id: string) =>
  `/api/v1/dict_active_status/${id}`;
export const postDictActiveStatusSearch = () =>
  `/api/v1/dict_active_status/search`;
