import {
  INotificationDto,
  INotificationListItemView,
  NotificationStatusCode,
} from '@/types/INotificationDto';
import LocalizedJson from '@/entities/LocalizedJson';
import DateTime from '@/entities/DateTime';
import Money from '@/entities/Money';
import TaxPeriod from '@/entities/TaxPeriod';
import Entity from '@/entities/Entity';
import { DictionaryEntity } from '@/entities/DictionaryEntity';
import { ViolationTypeEntity } from '@/entities/ViolationTypeEntity';
import NotificationTypeEntity from '@/entities/NotificationTypeEntity';
import JobExecutionEntity from '@/entities/JobExecutionEntity';

export default class NotificationEntity extends Entity {
  // Схема параметров запуска (JSON)
  public templateParams: any;

  // Статус уведомления
  public status: DictionaryEntity;

  // ИНН налогоплательщика
  public tin: string;

  // Налоговый период
  public taxPeriod: TaxPeriod;

  // Тип нарушения
  public violationType: ViolationTypeEntity;

  // Сумма нарушения
  public violationSum: Money;

  // Дата и время открытия нарушения
  public violationOpenedAt: DateTime;

  // Дата и время отсылки
  public sentAt: DateTime;

  // Тип уведомления
  public type: NotificationTypeEntity;

  // Вид уведомления
  public kind: DictionaryEntity;

  // Идентификатор нарушения
  public violationId: string;

  // Идентификатор версии нарушения
  public violationRecId: string;

  // Идентификатор предыдущего уведомления для уведомления-напоминания
  public originId: string;

  // Журнал запуска задач
  public execution: JobExecutionEntity | null;

  constructor(dto: INotificationDto | null) {
    super(dto);

    if (dto == null) {
      this.templateParams = null;
      this.status = new DictionaryEntity(null);
      this.tin = '';
      this.taxPeriod = TaxPeriod.createTaxPeriodFromDate(new Date());

      this.violationType = new ViolationTypeEntity(null);
      this.violationSum = new Money(0);
      this.violationOpenedAt = new DateTime(null);
      this.sentAt = new DateTime(null);
      this.type = new NotificationTypeEntity(null);
      this.kind = new DictionaryEntity(null);
      this.violationId = '';
      this.violationRecId = '';
      this.originId = '';
      this.execution = null;

      return;
    }

    this.templateParams = dto.templateParams;
    this.status = new DictionaryEntity(dto.status);
    this.tin = dto.tin;
    this.taxPeriod = new TaxPeriod(dto.taxPeriod);
    this.violationType = new ViolationTypeEntity(dto.violationType);
    this.violationSum = new Money(dto.violationSum);
    this.violationOpenedAt = new DateTime(dto.violationOpenedAt);
    this.sentAt = new DateTime(dto.sentAt);
    this.type = new NotificationTypeEntity(dto.type);
    this.kind = new DictionaryEntity(dto.kind);
    this.violationId = dto.violationId;
    this.violationRecId = dto.violationRecId;
    this.originId = dto.originId;
    this.execution =
      dto.execution == null ? null : new JobExecutionEntity(dto.execution);
  }

  public get notificationStatusBadge(): {
    color: string;
    label: string;
  } {
    switch (this.status.code) {
      case NotificationStatusCode.NEW:
        return {
          color: 'warning',
          label: this.status.title.localizedValue,
        };
      case NotificationStatusCode.SENT:
        return {
          color: 'primary',
          label: this.status.title.localizedValue,
        };
      case NotificationStatusCode.SEND_ERROR:
        return {
          color: 'danger',
          label: this.status.title.localizedValue,
        };
      case NotificationStatusCode.READ:
        return {
          color: 'success',
          label: this.status.title.localizedValue,
        };
      default:
        return {
          color: 'neutral',
          label: 'sending_status.unknown',
        };
    }
  }

  public getNotificationListItemView(): INotificationListItemView {
    return {
      id: this.id,
      created: this.created.dateTime,
      updated: this.updated.dateTime,
      type: this.type.title.localizedValue,
      notificationStatusBadgeColor: this.notificationStatusBadge.color,
      notificationStatusBadgeLabel: this.notificationStatusBadge.label,
      violationTypeTitle: this.violationType.title.localizedValue,
    };
  }

  public static noNotificationStatusBadge(): {
    color: string;
    label: string;
  } {
    return {
      color: 'warning',
      label: new LocalizedJson({
        en: 'Not sent',
        ru: 'Не отправлено',
      }).localizedValue,
    };
  }
}
