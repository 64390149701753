import { action, observable } from 'mobx';
import StoreConstructor from '@/store/core/StoreConstructor';
import { RootStores } from '@/store/core/RootStore';
import MessageChannelList from '@/store/notification/messageChannelList';
import NotificationStatusList from '@/store/notification/notificationStatusList';
import NotificationKindList from '@/store/notification/notificationKindList';

export default class DictionariesStore extends StoreConstructor {
  @observable public messageChannelList: MessageChannelList;
  @observable public notificationStatusList: NotificationStatusList;
  @observable public notificationKindList: NotificationKindList;

  constructor(stores: RootStores) {
    super(stores);

    this.messageChannelList = new MessageChannelList(stores);
    this.notificationStatusList = new NotificationStatusList(stores);
    this.notificationKindList = new NotificationKindList(stores);
  }

  @action.bound
  public async fetch(): Promise<void> {
    await Promise.all([
      this.messageChannelList.fetch(),
      this.notificationStatusList.fetch(),
      this.notificationKindList.fetch(),
    ]);
  }
}
