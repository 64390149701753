import { action, observable } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import { ContentTypeCode } from '@/types/IContentDto';
import { stoCtaNotificationApi } from '@/api';
import {
  getMessageBodyBin,
  getNotificationDefaultContent,
} from '@/api/endpoints/notification';
import { downloadFileFromBuffer } from '@/utils';
import { MessageEntity } from '@/entities/MessageEntity';
import { IMessageDto } from '@/types/IMessageDto';

export default class Message extends StoreConstructor {
  @observable public id: string;
  @observable public loading: boolean;
  @observable public message: MessageEntity;

  constructor(id: string, stores: RootStores) {
    super(stores);

    this.id = id;
    this.loading = false;
    this.message = new MessageEntity(null);
  }

  @action.bound
  public async update(id: string): Promise<void> {
    this.id = id;
    await this.fetch();
  }

  @action.bound
  public async downloadContent(message: MessageEntity): Promise<void> {
    let response = null;
    switch (message.bodyContentType.code) {
      case ContentTypeCode.TEXT:
        downloadFileFromBuffer(
          'notification.txt',
          message.body,
          message.bodyContentType.mediaType,
        );
        break;

      case ContentTypeCode.HTML:
        downloadFileFromBuffer(
          'notification.html',
          message.body,
          message.bodyContentType.mediaType,
        );
        break;

      case ContentTypeCode.PDF:
        response = await stoCtaNotificationApi.get(
          getMessageBodyBin(message.id),
          { responseType: 'blob' },
        );

        downloadFileFromBuffer(
          'notification.pdf',
          response.data,
          message.bodyContentType.mediaType,
        );
        break;
    }
  }

  private async fetch(): Promise<void> {
    try {
      this.loading = true;
      const response = await stoCtaNotificationApi.get<IMessageDto>(
        getNotificationDefaultContent(this.id),
      );
      this.message = new MessageEntity(response.data);
    } catch (e: any) {
      console.error('Content fetch: ', e);
    } finally {
      this.loading = false;
    }
  }
}
